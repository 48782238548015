// This file is relevant for test stage (build to Backend)
const baseUrl = '';

export const environment = {
  production: false,
  baseUrl: baseUrl,
  auth: {
    domain: 'siemens-qa-00169.eu.auth0.com',
    clientId: '4ja1TjcnB0fUmOCsJJSl0DZqmV5BfN5A',
    authorizationParams: {
      audience: 'excel-calculator-test',
      redirect_uri: window.location.origin,
    },
    errorPath: '/error'
  },
  httpInterceptor: {
    allowedList: [`${baseUrl}*`],
  },
};  